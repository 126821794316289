<template>
  <div class="container">
    <header class="jumbotron">
      <h3>
        <strong>Meus dados</strong> 
      </h3>
    </header>
    <p>
      <strong>Nome:</strong>
      {{ profile.name }}
    </p>
    <p>
      <strong>Título:</strong>
      {{ profile.titlenumber }}
    </p>
    <p>
      <strong>Email:</strong>
      {{profile.email}}
    </p>
    <p>
      <strong>Tipo:</strong>
      {{ profile.role == 'holder' ? 'Titular' : 'Dependente' }}
    </p>
    <router-link to="/changepassword" class="btn btn-primary btn-lg w-100 p-3">
      Alterar senha
    </router-link>
  </div>
</template>

<script>
import ApiAxios from '../services/api-axios';
import { sAlert } from './utils/jalerts';

export default {
  name: 'Profile',
  data() {
    return {
      profile: {}
    } 
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    }
  },
  mounted() {
    if (!this.currentUser) return this.$router.push('/login');
    this.fetchGetData();
  },
  methods: {
    async fetchGetData() {
			this.$root.loadingVisible = true;
			ApiAxios.get(`associate/me/profile`)
			.then((res) => {
				this.profile = res.data.associate;
				this.$root.loadingVisible = false;
			})
			.catch((e) => {
				this.$root.loadingVisible = false;
				if (e.response.status == 403) {
					this.$router.back();
					return sAlert({ type: 'warning', text: 'Ops, ' + e.response.data });  
				}
				sAlert({ type: 'danger', text: 'Ops, houve um erro: ' + e.response.data });
			});
		},
  }
};
</script>